'use client';

import { DateTime } from 'luxon';
import getYouTubeID from 'get-youtube-id';
import Image from 'next/image';

export default function VideoCard({ item, active, setActive, idx }: any) {
    const isActive = active === idx;
    return (
        <>
            <div
                className="cursor-pointer flex gap-5 p-5 relative shadow-xl"
                style={{ border: isActive ? '2px solid #05B4A2' : '2px solid gray' }}
                onClick={() => setActive(idx)}
            >
                <div className="flex gap-5">
                    {isActive && (
                        <p className="text-[#05B4A2] absolute bg-gray-500 top-[-13px] px-2 font-semibold">
                            Now Playing
                        </p>
                    )}
                    <Image
                        className="w-[180px] rounded-sm  hover:scale-105 transition-all "
                        src={`https://img.youtube.com/vi/${getYouTubeID(item?.mediaLink)}/hqdefault.jpg`}
                        alt="YouTube Thumbnail"
                        width={150}
                        height={100}
                    />
                    <div className="flex flex-col justify-center flex-grow h-full overflow-hidden">
                        <p className="md:text-xl text-sm text-white hover:text-[#05B4A2] font-bold line-clamp-2 capitalize transition-all">{item?.description}</p>
                        <p className="text-white">{DateTime.fromISO(item?.vohTvDate).toFormat('dd-MMM-yyyy')}</p>
                    </div>
                </div>
            </div>
        </>
    );
}
