import { Box, Button, Modal } from '@mui/material';

const redirectModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
};

export default function VOHModal({ open, setOpen }: any) {
    return (
        <Modal
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={redirectModalStyle}>
                <div>
                    <p className="text-xl text-gray-600">
                        You will be redirected to VOH Incubator. Are you sure you want to continue ?
                        <span className="text-md"></span>
                    </p>
                    <div className="flex items-center gap-x-4 mt-4">
                        <Button variant="contained" color="error" onClick={() => setOpen(false)}>
                            Cancel
                        </Button>
                        <a
                            href="https://vohincubator.org/"
                            target="_blank"
                            referrerPolicy="no-referrer"
                            rel="noopener noreferrer"
                        >
                            <Button variant="contained" color="primary">
                                Take me there
                            </Button>
                        </a>
                    </div>
                </div>
            </Box>
        </Modal>
    );
}
