'use client';
import ReUsableSlider from '@/components/ReUsables/ReUsableSlider';
import { enCodeEventLink, enCodeLink } from '@/utils';
import Image from 'next/image';
import Link from 'next/link';

export default function MainSlider({ className, bannerData }: any) {
    return (
        <div className={`${className}`}>
            <ReUsableSlider slideNumber={1} autoPlay>
                {Array.isArray(bannerData) &&
                    bannerData.map((event: any, idx: number) => {
                        const { editorialId, headLine } = event;
                        let link = `${enCodeEventLink(event?.eventName, event?.eventId)}`;
                        if (editorialId && headLine) link = enCodeLink(headLine, editorialId);
                        return (
                            <Link href={link} target={'_self'} key={idx} className="h-full">
                                <Image
                                    loading="eager"
                                    src={event.eventBannerImage}
                                    width={800}
                                    height={500}
                                    alt="Banner Image"
                                    className="skeleton h-full max-h-[70vh] hover:scale-105 transition-all rounded-md"
                                />
                            </Link>
                        );
                    })}
            </ReUsableSlider>
        </div>
    );
}
