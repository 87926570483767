import Link from 'next/link';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function ReUsableSectionHeading({ name, href, arrow }: { name: string; href: string; arrow?: boolean }) {
    return (
        <>
            <div className="flex justify-between items-center mt-4">
                <h1 className="text-2xl font-semibold pt-1">{name}</h1>
                {arrow && (
                    <Link href={href} aria-label="Link">
                        <ArrowForwardIosIcon />
                    </Link>
                )}
            </div>
            <hr className="mt-2 h-1 bg-[#5F9DC1] mb-5 flex" />
        </>
    );
}
