import CircularProgress from '@mui/material/CircularProgress';

export default function LoadingComponent({ text, className }: { text?: boolean; className?: string }) {
    return (
        <div className={`h-full flex flex-col justify-center items-center gap-5 ${className}`}>
            <CircularProgress />
            {text && <h1>Loading...</h1>}
        </div>
    );
}
