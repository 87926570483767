'use client';

import classes from './video-campaigns.module.scss';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import VideoCard from './VideoCard';
import Link from 'next/link';
import LongArrow from '@/assets/longarrowright.svg';
import Image from 'next/image';
import { useState } from 'react';
import getYouTubeID from 'get-youtube-id';

export default function VideoCampaigns({ vohTvsData }: any) {
    if (!Array.isArray(vohTvsData)) return <h1>Something went wrong...</h1>;
    const [active, setActive] = useState(0);
    return (
        <div className="w-full bg-gray-500 mt-5 shadow-2xl pt-5">
            <div className={classes.videoCampaignContainer}>
                <div className="flex flex-col h-full">
                    <h1 className="text-2xl font-extrabold mb-4">Video Campaigns</h1>
                 <LiteYouTubeEmbed id={getYouTubeID(vohTvsData[active]?.mediaLink) as any} title=""/>
                    <h1 className="mt-5 capitalize line-clamp-3">{vohTvsData[active]?.description}</h1>
                    <Link
                        href="/allbroadcast"
                        className="cursor-pointer flex gap-2 font-sans text-[#05B4A2] font-bold text-xl my-2"
                    >
                        View Highlights <Image width={30} height={20} src={LongArrow} alt="" />
                    </Link>
                </div>
                <div>
                    <h1 className="text-2xl font-extrabold mb-4">Playlist</h1>
                    <div className="flex flex-col gap-5">
                        {Array.isArray(vohTvsData) &&
                            vohTvsData.slice(0, 3).map((voh, idx) => {
                                return (
                                    <VideoCard item={voh} key={idx} setActive={setActive} idx={idx} active={active} />
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
}
