'use client';
import Image from 'next/image';
import DOMPurify from 'isomorphic-dompurify';
import { DateTime } from 'luxon';

import classes from './event-card.module.scss';
import { Paper } from '@mui/material';
// import { Button } from '@mui/material';
import Link from 'next/link';
import { enCodeEventLink, formatDate } from '@/utils';

export default function EventCard({ event }: any) {
    return (
        <Link href={`${enCodeEventLink(event?.eventName, event?.eventId)}`} target="_self">
            <Paper className={`min-h-[150px] py-2 ${classes.container}`}>
                <div className={classes.left}>
                    <div className="min-w-[150px] max-w-[150px] h-auto cursor-pointer overflow-hidden">
                        <Image
                            src={event.eventBannerImage}
                            width={150}
                            height={75}
                            alt="Event Image"
                            className="hover:scale-110 transition-all"
                        />
                    </div>
                    <p className="text-white text-[0.70rem] bg-[#05B4A2]  absolute py-0.5 top-0 rounded-l rounded-full pl-1 pr-3">
                        {DateTime.fromISO(event?.endDate).toFormat('yyyy-MM-dd') < DateTime.now().toFormat('yyyy-MM-dd')
                            ? 'Ended'
                            : 'Upcoming'}
                    </p>
                    <p className="absolute bottom-0 left-0 text-sm text-black pl-1">
                        {formatDate(event?.startDate, false)}
                    </p>
                </div>
                <div className={classes.right}>
                    <h1 className={`font-bold line-clamp-4 hover:text-[#05B4A2] transition-all`}>{event.eventName}</h1>
                    <div
                        className="line-clamp-2 text-sm max-w-full text-gray-500 mt-1 pr-2 editor "
                        dangerouslySetInnerHTML={{
                            // __html: DOMPurify.sanitize(event?.groups?.overview?.description),
                            __html: DOMPurify.sanitize(event?.pageDescription),
                        }}
                    />
                    <div className="self-end mt-auto mr-2">
                        <button className=" hover:bg-[#05B4A2] hover:text-white  border-[#05B4A2] border-[1px] py-1 px-4 rounded text-[#05B4A2] transition-all duration-300  ">
                            <p className="text-xs">
                                {DateTime.fromISO(event?.endDate).toFormat('yyyy-MM-dd') <
                                DateTime.now().toFormat('yyyy-MM-dd')
                                    ? `Watch Now`
                                    : `Register`}
                            </p>
                        </button>
                    </div>
                </div>
            </Paper>
        </Link>
    );
}
