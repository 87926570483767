export const EventGalleryBreakPoint = {
    0: {
        slidesPerView: 1,
    },
    750: {
        slidesPerView: 3,
    },
    1050: {
        slidesPerView: 3,
    },
};

export const NewsBreakPoint = {
    0: {
        slidesPerView: 1,
    },
    750: {
        slidesPerView: 3,
    },
};

export const UpdateBreakPoint = {
    0: {
        slidesPerView: 1,
    },
    750: {
        slidesPerView: 2,
    },
};
