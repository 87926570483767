'use client';

import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import getYouTubeID from 'get-youtube-id';
import ReUsableSlider from '../ReUsables/ReUsableSlider';
import { EventGalleryBreakPoint } from '@/constants/home';
import ReUsableSectionHeading from '../ReUsables/ReUsableSectionHeading';
import { Paper } from '@mui/material';

export default function VOHTv({ vohTvData }: any) {
    return (
        <div className="mt-5">
            <ReUsableSectionHeading name="VOH TV" href="/allbroadcast" arrow />
            <ReUsableSlider slideNumber={5} gap={20} breakpoints={EventGalleryBreakPoint} loading>
                {Array.isArray(vohTvData) &&
                    vohTvData.slice(0, 6).map((voh, idx) => {
                        return (
                            <Paper key={idx} className="h-full">
                                <LiteYouTubeEmbed title="hi" id={getYouTubeID(voh.embeddedLink) as any} />
                                <p className="line-clamp-2 m-2">{voh.headLine}</p>
                            </Paper>
                        );
                    })}
            </ReUsableSlider>
        </div>
    );
}
